import axios from "axios";
import { iappUrl } from "../apirest";

export async function getUserNru(args) {
  try {
    let { courseid, userid, username } = args;
    console.log(username)
    console.log(courseid)
    let { data } = await axios.get(`${iappUrl}/${courseid}/${username}`);
    console.log({ getUserNru: data });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}
