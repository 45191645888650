import React, { useState } from "react";
import { Navbar } from "../../components/Navbar";
import { HeaderCourses } from "../../components/HeaderCourses";
import { ResumeCourses } from "../../components/ResumeCourses";
// import { CoursesContentSection } from "../../components/CoursesContentSection";
import { Footer } from "../../components/Footer";
import { CourseCard } from "../../components/CourseCard";
// import { useMoodle } from "../../contexts/MoodleContext";
import { CustomSpinner } from "../../components/Loader/custom.spinner";
import { FormSence } from "../../services/sence/form";
import { useNavigate } from "react-router-dom";
import { CustomDialog } from "../../components/Dialog";
import { defaultSenceValidation } from "../../utils/handler";
import { getUserNru } from "../../services/tipddy/nru";
import { useAuth } from "../../contexts/AuthContext";
import { Snackbar } from "../../components/Snackbar";
import { THEME_CONTEXT, THEME_SEVERITY } from "../../utils/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DEFAULT_SNACKBAR } from "../../utils/snackbar";
import { STRINGS } from "../../utils/strings";
import { loginMoodle, resetPasword } from "../../services/moodle/userauth";
import { useMoodleV2 } from "../../contexts/moodle";

const ListCourses = () => {
  const auth = useAuth();
  const { lang } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const isSence = localStorage.getItem("sence_session");
  const currentCourse = localStorage.getItem("current_course");
  const moodle = useMoodleV2();
  // const moodleV2 = useMoodleV2();
  const [modalForcePassword, showModalForcePassword] = useState(false);
  const [msg, setMsg] = React.useState({
    open: false,
    title: "",
    msg: "",
    confirm: "Ir a Home",
    cancel: "Ir al curso",
  });

  React.useEffect(() => {
    lang && console.log(lang["Home"]);
  }, [lang]);

  React.useEffect(() => {
    // !moodle.courses && moodle.getCourses();
    moodle.getCourses();
    if (localStorage.getItem(STRINGS.FORCEPASSWORD_ERROR_CODE)) {
      showModalForcePassword(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (moodle.courses && moodle.courses.length > 0) {
      console.log(auth.user)
      //
      /* getUserNru({
        courseid: moodle.courses[0].id,
        userid: auth.user.id,
        username:auth.user.username
      }).then((res) => {
        console.log(res)
      }) */
      //
      let size = moodle.courses.length;
      console.log(size)
      if (size === 1) {
        let isSenceCourse = defaultSenceValidation(moodle.courses[0]);
        if (isSenceCourse.isSence) {
          console.log('is sence')
          getUserNru({
            courseid: moodle.courses[0].id,
            userid: auth.user.id,
            username:auth.user.username
          }).then((res) => {
            if (res && res.length > 0) {
              let isNRU = res[0].rnu;
              if (isNRU && isSenceCourse.isSence) {
                setLoading(false);
                setMsg({
                  ...msg,
                  open: true,
                  title: "Bienvenido a Alumnos",
                  confirm: "Ir a Inicio",
                  cancel: "Iniciar sesion en Sence",
                });
              }
            } else {
              setLoading(false);
              setMsg({
                ...msg,
                open: true,
                msg: "Selecciona la navegacion",
                title: "Bienvenido a Alumnos",
              });
            }
          });
        } else {
          window.location.replace(`/detailcourse/${moodle.courses[0].id}`);
        }
      } else {
        setLoading(false);
      }
    }

    if (moodle.courses && moodle.courses.length === 0) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [moodle.courses]);

  React.useEffect(() => {
    if (isSence) navigate(`/detailcourse/${currentCourse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCancel() {
    setMsg({ ...msg, open: false });
    navigate(`/detailcourse/${moodle.courses[0].id}`);
  }

  function onConfirm() {
    setMsg({ ...msg, open: false });
  }

  const PageView = () => {
    return (
      <>
        {lang && (
          <>
            <FormSence />
            <Navbar />
            <HeaderCourses moodle={moodle} lang={lang} />
            <ResumeCourses>
              {moodle.courses &&
                moodle.courses.length > 0 &&
                moodle.courses.map((item, idx) => {
                  return (
                    <CourseCard
                      key={idx}
                      item={item}
                      moodle={moodle}
                      lang={lang}
                    />
                  );
                })}
            </ResumeCourses>
            <Footer />
            <CustomDialog
              open={msg.open}
              onConfirm={onConfirm}
              onCancel={onCancel}
              title={msg.title}
              confirm={msg.confirm}
              cancel={msg.cancel}
              courseid={
                moodle &&
                moodle.course &&
                moodle.courses.length > 0 &&
                moodle.courses[0].id
              }
              moodle={moodle}
              message={msg.msg}
              countdown
            />
            <ForcePasswordModal open={modalForcePassword} />
          </>
        )}
      </>
    );
  };

  return <>{loading ? <CustomSpinner /> : <PageView />}</>;
};

const ForcePasswordModal = ({ open }) => {
  const auth = useAuth();
  const { lang } = useAuth();
  const navigate = useNavigate();
  const moodlev2 = useMoodleV2();

  const [snackbar, setSnackbar] = useState({
    severity: "",
    visible: false,
    message: "",
  });
  const [errorAuth, setErrorAuth] = useState({
    error: false,
    message: "",
  });
  const [credentials, setCredentials] = useState({
    lastPassword: "",
    newPassWord: "",
    confirmPassword: "",
  });

  const handleSubmit = async () => {
    let username = localStorage.getItem("username");
    // let token = localStorage.getItem("token");
    try {
      await loginMoodle({ username, password: credentials.lastPassword });
      let reseted = await resetPasword({
        username,
        password: credentials.newPassWord,
        forcepasswordchange: 1,
      });

      localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);

      setSnackbar({
        visible: true,
        message: lang["Snackbars"].success_change,
        severity: THEME_SEVERITY.SUCCESS,
      });

      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
        auth.logout(moodlev2);
        navigate("/");
      }, 5000);

      console.log({ reseted });
    } catch (err) {
      setErrorAuth({
        error: true,
        message: "Error al validar la contraseña anterior",
      });
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth
          open={open}
          /* onClose={handleClose} */ PaperProps={{
            style: {
              backgroundColor: THEME_CONTEXT.NAV_LIGHT,
              paddingBottom: ".7rem",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: THEME_CONTEXT.DARK_MODE_BTN,
              color: "#fff",
              fontSize: 16,
              fontFamily:
                "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
            }}
          >
            {lang["Forcepassword"].title}
          </DialogTitle>
          <DialogContent className="mt-3">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={lang["Forcepassword"].first_input}
              type="email"
              fullWidth
              variant="standard"
              value={credentials.lastPassword}
              onChange={(e) =>
                setCredentials({ ...credentials, lastPassword: e.target.value })
              }
              inputProps={{ style: { fontSize: 12, color: "#000" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#000" } }}
            />
            {errorAuth.error && (
              <p style={{ fontSize: 12, color: "red", fontWeight: 400 }}>
                {errorAuth.message}
              </p>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={lang["Forcepassword"].second_input}
              type="email"
              fullWidth
              variant="standard"
              value={credentials.newPassWord}
              onChange={(e) =>
                setCredentials({ ...credentials, newPassWord: e.target.value })
              }
              inputProps={{ style: { fontSize: 12, color: "#000" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#000" } }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={lang["Forcepassword"].third_input}
              type="email"
              fullWidth
              variant="standard"
              value={credentials.confirmPassword}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  confirmPassword: e.target.value,
                })
              }
              inputProps={{ style: { fontSize: 12, color: "#000" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#000" } }}
            />
          </DialogContent>
          <DialogActions>
            {
              <Button
                disabled={Object.values(credentials).some((v) => v === "")}
                /* onClick={handleClose} */ onClick={() => handleSubmit()}
                variant="contained"
                sx={{
                  backgroundColor: THEME_CONTEXT.DAY_MODE_BTN,
                  fontSize: 12,
                  marginRight: 2,
                }}
              >
                {lang["Forcepassword"].send}
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};

export default ListCourses;
