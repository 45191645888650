import React from "react";
import {
  authMiddleware,
  getCurrentUser,
  loginMoodle /* requestAutoLogout */,
} from "../services/moodle/userauth";
import { STRINGS } from "../utils/strings";
import dic from "../utils/languages.json";

export const AuthContext = React.createContext();

/* actions to work on */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [verified, setVerified] = React.useState(false);
  const [lang, setLang] = React.useState(null);

  const actions = {
    RefreshAuth: async () => {
      try {
        const token = localStorage.getItem("token");
        const username = localStorage.getItem("username");
        const user = await getCurrentUser({ token, username });
        setUser(user);
        setVerified(true);
      } catch (e) {
        throw new Error(e);
      }
    },
    Authenticate: async () => {
      if (localStorage.getItem("token") && !user) {
        /*         console.log('refreshtoken') */
        return Promise.resolve(await actions.RefreshAuth());
      }
      setVerified(true);
    },
    Login: async function (credentials) {
      try {
        const token = await loginMoodle(credentials);
        const moodleUserInfo = await authMiddleware({
          token,
          username: credentials.username,
        });
        setUser(moodleUserInfo);
        setVerified(true);

        if (moodleUserInfo.errorcode === STRINGS.FORCEPASSWORD_ERROR_CODE) {
          localStorage.setItem(
            STRINGS.FORCEPASSWORD_ERROR_CODE,
            STRINGS.FORCEPASSWORD_ERROR_CODE
          );
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    login: () => window.location.replace("/"),
    logout: async (moodle = null) => {
      if (user && user.id) {
        localStorage.setItem("newaccess", user.id);
      }
      setUser(null);
      if (moodle.categories || moodle.courses || moodle.grades) {
        moodle.setCourses(null);
        moodle.setCategories(null);
        moodle.setGrades(null);
      }
      localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("username");
      localStorage.removeItem("last-access-cmid");
      localStorage.removeItem("sence_session");
      localStorage.removeItem("current_course");
      localStorage.removeItem("last_login_sence");
      localStorage.removeItem("darkMode");
      localStorage.removeItem("idSence");
      localStorage.removeItem("nru");
      // window.location.replace("/");
    },
    fetchDictionary: async () => {
      let url = window.location.href;
      let l = "";
      /* insertar funcion que maneje el idioma */
      if (url.includes("academy.tuclase")) {
        l = "en";
      } else {
        l = "es";
      }
      setLang(dic[l]);
    },
  };

  const value = {
    user,
    setUser,
    verified,
    lang,
    Authenticate: actions.Authenticate,
    Login: actions.Login,
    logout: actions.logout,
    login: actions.login,
    dictionary: actions.fetchDictionary,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
