import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_CREDENTIALS } from "../../utils/state";
import { LoginForm } from "../../components/LoginForm";
import { Snackbar } from "../../components/Snackbar";
import { DEFAULT_SNACKBAR } from "../../utils/snackbar";
import { THEME_SEVERITY } from "../../utils/theme";
// import { STRINGS } from "../../utils/strings";
import { useMoodleV2 } from "../../contexts/moodle";

const Login = () => {
  // if (localStorage.getItem("token")) window.location.replace("/listcourses");

  const auth = useAuth();
  const { lang } = useAuth();
  const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState(DEFAULT_CREDENTIALS);
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const moodlev2 = useMoodleV2();

  React.useEffect(() => {
    document.body.classList.add("imagen3");
    // localStorage.removeItem("token");
    // localStorage.removeItem("refresh");
    // localStorage.removeItem("username");
    // localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);
    auth.logout(moodlev2);
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    lang && console.log(lang["Login"]);
  }, [lang]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      setSnackbar({
        visible: true,
        message: lang["Snackbars"].missing_data,
        severity: THEME_SEVERITY.ERROR,
      });
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
      return;
    }
    let clean_username = credentials.username.replace(/\s/g, "").toLowerCase();
    try {
      setLoading(true);
      await auth.Login({ ...credentials, username: clean_username });
      setSnackbar({
        visible: true,
        message: lang["Snackbars"].success_login,
        severity: THEME_SEVERITY.SUCCESS,
      });
      setTimeout(() => {
        setLoading(false);
        navigate("/listcourses");
        setSnackbar(DEFAULT_SNACKBAR);
        setCredentials(DEFAULT_CREDENTIALS);
        document.body.classList.remove("imagen3");
      }, 1000);
    } catch (error) {
      console.log(error.message);
      setSnackbar({
        visible: true,
        message: lang["Snackbars"].invalid_access,
        severity: THEME_SEVERITY.ERROR,
      });
      setLoading(false);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  return (
    <>
      {lang && (
        <>
          <div className="container esp-log">
            <img
              src="https://par.tuclase.cl/loginalt/images/logo2.png"
              alt="logo"
              className="posi-logo posi-log-b"
            />
          </div>
          <div className="container ">
            <div className="row mx-4">
              <div className="col-md-1"></div>
              <div className="col-md-4  trans2 sac-new">
                <div className="fon-logo-new2 sec-desktop">
                  <p className="">
                    <img
                      src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                      alt="..."
                    />
                  </p>
                  <p className="iniciar-new">{lang["Login"].title}</p>
                </div>
                <div className="fon-logo-new2 sec-movil">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-xs-8 col-md-8">
                        <p className="">
                          <img
                            alt="img"
                            src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                            className="img-responsive"
                          />
                        </p>
                      </div>
                      <div className="col-xs-4 col-md-4"></div>
                    </div>
                    <p className="iniciar-new">{lang["Login"].title}</p>
                  </div>
                </div>
                <LoginForm
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  credentials={credentials}
                  loading={loading}
                  lang={lang["Login"]}
                />
              </div>
              <div className="col-md-7 text-center salir">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4"></div>
                </div>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: lang["Login"].instructions,
                  }}
                ></div> */}
                <h1 className="llamado mt-0" style={{ paddingTop: "90px" }}>
                  {lang["Login"].instructions_1}
                </h1>
                <h1 className="llamado mt-0">{lang["Login"].instructions_2}</h1>
                <p className="llamado-d">{lang["Login"].instructions_3}</p>
                {/* <h1 className="llamado">
                  Experiencias de <br />
                  aprendizaje
                </h1>
                <p className="llamado-d">
                  a la medida, actualizadas y efectivas
                </p> */}
              </div>
            </div>
          </div>
          <Snackbar
            severity={snackbar.severity}
            visible={snackbar.visible}
            message={snackbar.message}
          />
        </>
      )}
    </>
  );
};

export default Login;
