import React from "react";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_CHANGE_PASSWORD_CREDENTIALS } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import { DEFAULT_SNACKBAR } from "../../utils/snackbar";
import { ChangePasswordForm } from "../../components/LoginForm/ChangePasswordForm";
import { useValidToken } from "../../hooks/useValidToken";
import Wrapper from "../../components/LoginForm/Wrapper";
import { useBackground } from "../../hooks/useBackground";
import { fetcher } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { THEME_SEVERITY } from "../../utils/theme";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { lang } = useAuth();
  const [credentials, setCredentials] = React.useState(
    DEFAULT_CHANGE_PASSWORD_CREDENTIALS
  );
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const { data, isValid } = useValidToken(window.location.search);
  /*   console.log(data); */
  const URL =
    "https://par.tuclase.cl/tipddy/admin/iapp/services/restore-password.php/user/changepassword";
  const headers = { "Content-Type": "appplication/json" };

  React.useEffect(() => {
    lang && console.log(lang["Recovery"]);
  }, [lang]);

  useBackground();

  const handleSnackbar = (snack) => {
    setSnackbar(snack);
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const clean = () => {
    setCredentials(DEFAULT_CHANGE_PASSWORD_CREDENTIALS);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].invalid_access,
        severity: THEME_SEVERITY.ERROR,
      });
      return;
    }
    if (credentials.password !== credentials.confirm_password) {
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].invalid_access,
        severity: THEME_SEVERITY.ERROR,
      });
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          password: credentials.password,
          token: data.setdata.token,
        }),
      });
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].success_change,
        severity: THEME_SEVERITY.SUCCESS,
      });
      clean();
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error.message);
      setSnackbar({
        visible: true,
        message: lang["Snackbars"].invalid_access,
        severity: THEME_SEVERITY.ERROR,
      });
      setLoading(false);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  if (!isValid) {
    return (
      <Wrapper>
        <p className="text-white text-center my-5">Token ya expiró</p>
      </Wrapper>
    );
  }

  return (
    <>
      {lang && (
        <>
          <Wrapper>
            <ChangePasswordForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              credentials={credentials}
              loading={loading}
            />
          </Wrapper>
          <Snackbar
            severity={snackbar.severity}
            visible={snackbar.visible}
            message={snackbar.message}
          />
        </>
      )}
    </>
  );
};
export default ChangePassword;
