export const ipMoodle = "138.68.226.91";
export const baseURL = "https://par.tuclase.cl/";
// export const tipddytoken = "122c8d73318e7ae3c25261962bea48c1";
export const tipddytoken = "6efdf3eb77bc8beca386c774fab608aa";
export const moodleURL = `${baseURL}webservice/rest/server.php?wstoken=`;
export const iappUrl = "https://tuclase-rnu.iapp.cl/api/rnu";

export const API = {
  dev: "http://localhost:3000/",
  prod: "https://alumnos.tuclase.cl",
};
