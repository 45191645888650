import React from "react";
import {
  getCoreCourseContent,
  getCourseProgress,
  getFeedback,
  getGradeReportbyCourse,
  getOverviewGrades,
  getUserCourses,
  setActivityProgressCompletedManually,
  setActivityProgressCompletedOverride,
  testing,
} from "../services/moodle/courses";
import { MIME_TYPES, TIPDDY } from "../utils/state";
// import { extractSRC, mount } from "../utils";
import { DEFAULT_VISUAL_CONTENT } from "../utils/state";
import { useAuth } from "./AuthContext";
import { requestAutoLogin } from "../services/moodle/userauth";
import { baseURL } from "../services/apirest";
// import { STRINGS } from "../utils/strings";
/* import axios from "axios"; */

export const MoodleContext = React.createContext(null);

/* actions to work on */
export const MoodleProvider = ({ children }) => {
  const auth = useAuth();
  const { user } = auth;
  const senceCouseCod = React.useRef(null);
  const [courses, setCourses] = React.useState(null);
  const [visualContent, setVisualContent] = React.useState(
    DEFAULT_VISUAL_CONTENT
  );
  const [currentModule, setCurrentModule] = React.useState(null);
  const [currentModules, setCurrentModules] = React.useState(null);
  const [currentCourse, setCurrentCourse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [tracker, setTracking] = React.useState(null);

  const loadingFinished = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const actions = {
    getBanner: async (id) => {
      try {
        let banner = null;
        let tempBannerString = null;
        let content = await getCoreCourseContent({
          course_id: id,
          token: user.token,
        });
        if (content) {
          // console.log(content)
          let modules = content[0].modules;
          for (const module of modules) {
            let isBanner =
              module.description && module.description.includes("portada-");
            tempBannerString = isBanner && module.description;
            if (tempBannerString) {
              let reg = /<img[^>]+src="(https:\/\/[^">]+)"/g;
              let src = reg.exec(tempBannerString)[1];
              banner = src && src;
            }
          }
        }
        return banner;
      } catch (err) {
        throw new Error(err);
      }
    },
    /**
     *
     * @param {*} item
     * @returns
     */
    getImgUrl: (item) => `${item.overviewfiles[0].fileurl}?token=${user.token}`,
    /**
     *
     * @param {*} fileurl
     * @returns
     */
    downloadfile: (fileurl) => window.open(`${fileurl}&token=${user.token}`),
    /**
     *
     * @param {*} filedata
     * @param {*} mimetype
     */
    getVisualContent: (module, pageurl = "") => {
      setCurrentModule(module);
      setLoading(true);
      const {
        tipddymodules,
        tipddyurl,
        tipddymimetype,
        summary,
        contents,
        /* description, */
      } = module;

      if (summary) {
        setVisualContent({
          type: TIPDDY.summary,
          content: summary,
        });
        return loadingFinished();
      }
      if (tipddymodules === TIPDDY.folder) {
        setVisualContent({
          type: TIPDDY.folder,
          content: contents,
        });
        return loadingFinished();
      }
      if (tipddymodules === TIPDDY.label) {
        setVisualContent({
          type: TIPDDY.label,
          content: module.description,
        });
        return loadingFinished();
      }
      if (tipddymimetype === MIME_TYPES.pdf) {
        setVisualContent({
          type: tipddymimetype,
          content: `${tipddyurl}?token=${user.token}`,
        });
        return loadingFinished();
      }
      if (tipddymodules === TIPDDY.video) {
        setVisualContent({
          type: tipddymodules,
          content: module.contents[0].fileurl,
        });
        return loadingFinished();
      }
      if (tipddymodules === TIPDDY.teamsmicrosoft) {
        window.open(tipddyurl, "_blank");
        return loadingFinished();
      }
      if (tipddymodules === TIPDDY.xslx) {
        actions.downloadfile(tipddyurl);
        return loadingFinished();
      } else {
        setVisualContent({
          type: "embed",
          content: pageurl,
        });
        return loadingFinished();
      }
    },
    /*
     *
     * @returns
     */
    getInitalContent: async function () {
      console.log({ user });
      try {
        const data = await getUserCourses({
          token: user.token,
          userid: user.id,
        });
        console.log({ data });
        // /* se agrega condicional para evitar spin infinito cuando el token es invalido */
        // if (
        //   data.errorcode === "invalidtoken" &&
        //   !localStorage.getItem(STRINGS.FORCEPASSWORD_ERROR_CODE)
        // ) {
        //   /* o retornar variable que despliegue cartel que no hay cursos */
        //   auth.logout();
        // }
        setCourses(data);
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    /**
     *
     * @param {*} course_id
     * @returns
     */
    getCourseContent: async function (course_id) {
      const args = {
        token: user.token,
        id: user.id,
        course_id: course_id,
      };
      return Promise.all([
        await getGradeReportbyCourse(args),
        await getCoreCourseContent(args),
        await getCourseProgress(args),
        await getUserCourses(user.token, user.id),
      ]);
    },
    /**
     *
     * @param {*} course_id
     * @returns
     */
    getProgress: async function () {
      return Promise.resolve(await getOverviewGrades(user.token));
    },
    getProgressDetail: async function (course_id) {
      const args = {
        token: user.token,
        id: user.id,
        course_id: course_id,
      };
      return Promise.all([
        await getGradeReportbyCourse(args),
        await getFeedback(args),
        await getCourseProgress(args),
      ]);
    },
    /**
     *
     * @param {*} course_id
     * @returns
     */ getProgressById: async function (course_id) {
      try {
        const args = {
          token: user.token,
          id: user.id,
          course_id: course_id,
        };
        const data = await getCourseProgress(args);
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    /**
     *
     * @param {*} module
     */
    setModuleProgress: async (module) => {
      const args = {
        token: user.token,
        completed: 1,
        cmid: module.id,
      };
      return Promise.resolve(await setActivityProgressCompletedManually(args));
    },
    /**
     *
     * @param {*} module
     */
    setModuleProgressOverride: async (module) => {
      const args = {
        userid: user.id,
        token: user.token,
        completed: 1,
        cmid: module.id,
      };
      return Promise.resolve(await setActivityProgressCompletedOverride(args));
    },

    getResourceView: async (resourceurl) => {
      try {
        const {key, data} = await requestAutoLogin(user.username);
        console.log(data)
        const url = `${baseURL}auth/userkey/login.php?key=${key}&wantsurl=${resourceurl}`;
        /*         const data = await axios.get(url) */
        /*         console.log(data) */
        return url;
      } catch (e) {
        throw new Error(e);
      }
    },

    testing: async (courseid) => {
      try {
        return Promise.resolve(await testing(user.token, courseid));
      } catch (e) {
        throw new Error(e);
      }
    },
  };

  const value = {
    loading,
    courses,
    visualContent,
    currentModule,
    senceCouseCod,
    currentCourse,
    currentModules,
    tracker,
    setTracking,
    setCurrentModules,
    setCurrentModule,
    setCurrentCourse,
    setVisualContent,
    downloadfile: actions.downloadfile,
    getVisualContent: actions.getVisualContent,
    getCourseImg: actions.getImgUrl,
    getCourses: actions.getInitalContent,
    getCourseContent: actions.getCourseContent,
    getProgressById: actions.getProgressById,
    setModuleProgress: actions.setModuleProgress,
    setModuleProgressOverride: actions.setModuleProgressOverride,
    getResourceView: actions.getResourceView,
    getProgressDetail: actions.getProgressDetail,
    getProgress: actions.getProgress,
    getActivyId: actions.getActivyId,
    getBanner: actions.getBanner,
    testing: actions.testing,
  };

  return (
    <MoodleContext.Provider value={value}>{children}</MoodleContext.Provider>
  );
};

export const useMoodle = () => React.useContext(MoodleContext);
