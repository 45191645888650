import "./index.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ResourceLabelComponent } from "../ResourceLabelComponent";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";

const LABEL = "label";

export const CourseContentModuleRowComponent = ({
  module,
  moodle,
  idx,
  container,
}) => {
  const theme = useTheme();
  const rowContainer = useRef(null);
  const [newmodules, setnewmodules] = useState(null);
  const [isCurrentModule, setCurrentModule] = useState(null);
  const [isUserVisible, setUserVisible] = useState(true);
  const [spreed, setSpreed] = useState(false);

  useEffect(() => {
    let filtered = module.modules.filter((res) => res.modname !== LABEL);
    setnewmodules(filtered);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log({ currentCourse: module });
    setUserVisible(module.uservisible);
  }, [module.uservisible]);

  // const handleScrollCurrentModule = () => {
  //   /* Se guarda el valor de todos los containers de Unidad/Modulo */
  //   let modules = document.querySelectorAll(".wrapper-row");
  //   /* Se guarda el valor del contenedor padre, ya que la variable container no venia*/
  //   let father_content_container = document.querySelector(".content-container");
  //   for (let tag of modules) {
  //     /* recorremos los contenedores modules, y buscamos el valor rgb del background */
  //     let bgColor = window.getComputedStyle(tag).backgroundColor;
  //     if (bgColor === "rgb(255, 204, 0)") {
  //       /* se compara el valor rgb con el amarillo que posee el repositorio al haber un bloque desplegado y se scrollea */
  //       father_content_container.scrollTo({
  //         top: tag.offsetTop - 130,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    /* console.log("isCurrentRow: ", module.id, moodle.currentModuleId); */
    let isCurrent = module.id === moodle.currentModuleId;
    setCurrentModule(isCurrent);
    isCurrent ? setSpreed(true) : setSpreed(false);
    // if (isCurrent) {
    //   handleScrollCurrentModule();
    // }
    // eslint-disable-next-line
  }, [moodle.currentModuleId]);

  const handleSpreed = async (module) => {
    module.modules.length > 0 && setSpreed(!spreed);
    await handleSummary(module);
  };

  async function handleSummary(module) {
    if (module.summary !== "") {
      await moodle.requestPageEmbed({ pageurl: module.summary, module });
    }
  }

  return (
    <div
      ref={rowContainer}
      className="wrapper-row"
      style={{
        backgroundColor: isCurrentModule
          ? theme.mode === "dark"
            ? "#222222"
            : "#FFCC00"
          : "#B4B4B4",
        border: isCurrentModule ? "1px solid #fff" : "unset",
        opacity: 1,
        pointerEvents: !isUserVisible ? "none" : "unset",
      }}
    >
      <div className="row-container" onClick={() => handleSpreed(module)}>
        <div className="circle-btn-container mx-2">
          <div
            className="circle-btn"
            style={{ backgroundColor: isCurrentModule ? "#FFCC00" : "#FFCC00" }}
          >
            <p style={{ color: "#000" }}>{idx + 1}</p>
          </div>
        </div>

        <div className="title-container" style={{ width: "100%" }}>
          <div
            className="title-btn"
            style={{
              fontSize: "14px",
              color: isCurrentModule
                ? theme.mode === "dark"
                  ? "#fff"
                  : "#333333"
                : "#333",
            }}
          >
            {module.name}
          </div>
          <div>
            {module.hasProgress && (
              <ProgressBar
                progress={module.rawProgress}
                theme={theme}
                isCurrentModule={isCurrentModule}
              />
            )}
          </div>
          {module.availabilityinfo && (
            <p
              style={{ fontSize: 10, marginTop: 2, opacity: 1 }}
              dangerouslySetInnerHTML={{ __html: module.availabilityinfo }}
            ></p>
          )}
        </div>

        {newmodules && newmodules.length > 0 && (
          <div>
            {spreed ? (
              <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: "#fff" }} />
            )}
          </div>
        )}
      </div>
      <div
        style={{
          paddingLeft: "26px",
        }}
      >
        {spreed &&
          newmodules &&
          newmodules.map((resource) => {
            return (
              <ResourceLabelComponent
                container={container}
                module={module}
                resource={resource}
                key={resource.id}
                moodle={moodle}
                idx={idx}
                isCurrentModule={isCurrentModule}
              />
            );
          })}
      </div>
    </div>
  );
};

const ProgressBar = ({ progress, theme, isCurrentModule }) => {
  let width = "100";
  let progressWidth = (progress * width) / 100;

  return (
    <div
      className="title-container"
      style={{
        transform: "translateY(10px)",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: `${width}%`,
          height: 5,
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fdbf00",
            width: `${progressWidth}%`,
            height: 5,
            borderRadius: "5px",
          }}
        ></div>
      </div>
      <span
        style={{
          color: isCurrentModule
            ? theme.mode === "dark"
              ? "#898989"
              : "#333"
            : "#333",
          fontSize: "10px",
          marginTop: "5px",
        }}
      >
        {progress}% completado
      </span>
    </div>
  );
};
