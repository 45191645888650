import { query } from "../../utils";
import { /* SENCE_DEV,  */ SENCE_PROD } from "./state";

export const SenceValidation = (args) => {
  let { idSence, auth, session = null, isLogin, nru } = args;
  // todo uncomment this

  console.log(auth.user)
  // ! caso de cambio de idnumber por username
  /* let dv = auth.user.idnumber[auth.user.idnumber.length - 1] || null;
  let rut = auth.user.idnumber.slice(0, -1) || null; */
  let dv = auth.user.username[auth.user.username.length - 1] || null;
  let rut = auth.user.username.slice(0, -1) || null;
  let rut_value = null;
  if (rut) {
    rut_value = `${rut}-${dv}`;
  }

  let form = query("#form-sence");
  let CodSence = query("#CodSence");
  let idSesionAlumno = query("#IdSesionAlumno");
  let runAlumno = query("#RunAlumno");
  let codCurso = query("#CodigoCurso");

  CodSence.value = idSence;
  idSesionAlumno.value = auth.user.token;
  runAlumno.value = rut_value; // todo rut_value;
  codCurso.value = nru;

  if (!isLogin) {
    // Función para obtener la URL base
    const getBaseUrl = () => {
      const { protocol, hostname, port } = window.location;
      // Construir la URL base con el puerto si existe
      const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
      return baseUrl;
    };
    // Función para codificar en Base64 considerando caracteres no ASCII
    const codificarBase64 = (texto) => {
      return window.btoa(unescape(encodeURIComponent(texto)));
    };
    // Ejecutar cuando se cargue la aplicación
    const baseUrl = getBaseUrl();
    const baseUrlCodificada = codificarBase64(baseUrl);

    console.log("URL Base:", baseUrl);
    console.log("URL Base codificada en Base64:", baseUrlCodificada);

    let idSesionSence = query("#IdSesionSence");
    idSesionSence.value = session.IdSesionSence;
    /*     query("#form-sence").action = SENCE_DEV.URL_LOGOUT;
    query("#UrlRetoma").value = SENCE_DEV.URL_RETURN_LOGOUT;
    query("#UrlError").value = SENCE_DEV.URL_LOGOUT_ERROR; */
    query("#form-sence").action = SENCE_PROD.URL_LOGOUT;
    query("#UrlRetoma").value = `${SENCE_PROD.URL_RETURN_LOGOUT}?u=${baseUrlCodificada}`;
    query("#UrlError").value = `${SENCE_PROD.URL_LOGOUT_ERROR}?u=${baseUrlCodificada}`;
  }

  console.log({
    //rut_value, // todo uncomment ;
    form,
    CodSence: CodSence.value,
    idSesionAlumno: idSesionAlumno.value,
    runAlumno: runAlumno.value,
    codCurso: codCurso.value,
  });

  form.submit();
};
/* export const SenceValidation = (idSence, auth, sence = null, isLogin) => {
  const dv = auth.user.idnumber[auth.user.idnumber.length - 1];
  const rut = auth.user.idnumber.slice(0, -1);
  const rut_value = `${rut}-${dv}`;
  const form = document.querySelector("#form-sence");
  const CodSence = document.querySelector("#CodSence");
  const idSesionAlumno = document.querySelector("#IdSesionAlumno");
  const runAlumno = document.querySelector("#RunAlumno");

  CodSence.value = idSence;
  idSesionAlumno.value = auth.user.token;
  runAlumno.value = rut_value;

  if (!isLogin) {
    let idSesionSence = document.querySelector("#IdSesionSence");
    idSesionSence.value = sence.IdSesionSence;
    document.querySelector("#form-sence").action = SENCE_PROD.URL_LOGOUT;
    document.querySelector("#UrlRetoma").value = SENCE_PROD.URL_RETURN_LOGOUT;
    document.querySelector("#UrlError").value = SENCE_PROD.URL_LOGOUT_ERROR;
  }

  form.submit();
}; */
