import React from "react";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_FORGOT_CREDENTIALS } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import { DEFAULT_SNACKBAR } from "../../utils/snackbar";
import { ForgotPasswordForm } from "../../components/LoginForm/ForgotPasswordForm";
import { fetcher } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { THEME_SEVERITY } from "../../utils/theme";

//usuario y correo
const ForgotPassword = () => {
  const [credentials, setCredentials] = React.useState(
    DEFAULT_FORGOT_CREDENTIALS
  );
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const { lang } = useAuth();
  const URL =
    "https://par.tuclase.cl/tipddy/admin/iapp/services/restore-password.php/user/forgotpassword";
  const headers = { "Content-type": "application/json" };

  React.useEffect(() => {
    document.body.classList.add("imagen3");
  }, []);

  React.useEffect(() => {
    lang && console.log(lang["Recovery"]);
  }, [lang]);

  const handleSnackbar = (snack) => {
    setSnackbar(snack);
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const cleaner = () => {
    setCredentials(DEFAULT_FORGOT_CREDENTIALS);
    setLoading(false);
  };

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    console.log("submit user");
    if (credentials.username === "") {
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].missing_username,
        severity: THEME_SEVERITY.ERROR,
      });
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ username: credentials.username }),
      });
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].success_recovery,
        severity: THEME_SEVERITY.SUCCESS,
      });
      cleaner();
    } catch (error) {
      setLoading(false);
      if (error.message === "400") {
        handleSnackbar({
          visible: true,
          message: lang["Snackbars"].error_username,
          severity: THEME_SEVERITY.ERROR,
        });
      } else {
        handleSnackbar({
          visible: true,
          message: lang["Snackbars"].server_error,
          severity: THEME_SEVERITY.ERROR,
        });
      }
    }
  };

  const handleSubmitEmail = async (e) => {
    console.log(credentials);
    e.preventDefault();
    if (credentials.email === "") {
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].missing_email,
        severity: THEME_SEVERITY.ERROR,
      });
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email: credentials.email }),
      });
      handleSnackbar({
        visible: true,
        message: lang["Snackbars"].success_recovery,
        severity: THEME_SEVERITY.SUCCESS,
      });
      cleaner();
    } catch (error) {
      setLoading(false);
      console.log(typeof error.message);
      if (error.message === "400") {
        handleSnackbar({
          visible: true,
          message: lang["Snackbars"].error_email,
          severity: THEME_SEVERITY.ERROR,
        });
      } else {
        handleSnackbar({
          visible: true,
          message: lang["Snackbars"].server_error,
          severity: THEME_SEVERITY.ERROR,
        });
      }
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  return (
    <>
      {lang && (
        <>
          <div className="container esp-log">
            <img
              src="https://par.tuclase.cl/loginalt/images/logo2.png"
              alt="logo"
              className="posi-logo posi-log-b"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4  trans2 sac-new">
                <div className="fon-logo-new2 sec-desktop">
                  <p className="">
                    <img
                      src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                      alt="..."
                    />
                  </p>
                  <p className="iniciar-new">{lang["Recovery"].title}</p>
                </div>
                <div className="fon-logo-new2 sec-movil">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-xs-8 col-md-8">
                        <p className="">
                          <img
                            alt="img"
                            src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                            className="img-responsive"
                          />
                        </p>
                      </div>
                      <div className="col-xs-4 col-md-4"></div>
                    </div>
                    <p className="iniciar-new">{lang["Recovery"].title}</p>
                  </div>
                </div>
                <ForgotPasswordForm
                  handleChange={handleChange}
                  handleSubmitUser={handleSubmitUser}
                  handleSubmitEmail={handleSubmitEmail}
                  credentials={credentials}
                  loading={loading}
                  lang={lang}
                />
              </div>
              <div className="col-md-7 text-center salir">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4"></div>
                </div>
                <h1 className="llamado mt-0" style={{ paddingTop: "90px" }}>
                  {lang["Recovery"].instructions_1}
                </h1>
                <h1 className="llamado mt-0">
                  {lang["Recovery"].instructions_2}
                </h1>
                <p className="llamado-d">{lang["Recovery"].instructions_3}</p>
              </div>
            </div>
          </div>
          <Snackbar
            severity={snackbar.severity}
            visible={snackbar.visible}
            message={snackbar.message}
          />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
