import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { THEME_CONTEXT } from "../../../utils/theme";

export const HeaderProgress = ({ lang }) => {
  const theme = useTheme();
  return (
    <>
      <div style={{ paddingTop: 20 }}></div>
      <h1 className="historial" style={theme.styles.title_text}>
        {lang["History"].second_title}
      </h1>
      <ul className="progress-list mt-5">
        <li className="progress-list" style={theme.styles.title_text}>
          <div
            className="square"
            style={{ backgroundColor: THEME_CONTEXT.NAV_LIGHT }}
          ></div>
          &nbsp;{lang["History"].complete}
        </li>
        <li className="progress-list" style={theme.styles.title_text}>
          <div
            className="square"
            style={{ backgroundColor: THEME_CONTEXT.DAY_MODE_BTN }}
          ></div>
          &nbsp;{lang["History"].pending}
        </li>
      </ul>
    </>
  );
};
