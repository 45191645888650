/* Crear Un formulario html con los siguientes inputs en name y en id y en value */
import { useEffect, useState } from "react";
import { /* SENCE_DEV, */ SENCE_PROD } from "../state";

export const FormSence = () => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    // Función para obtener la URL base
    const { protocol, hostname, port } = window.location;
    const getBaseUrl = () => {
      // Construir la URL base con el puerto si existe
      const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
      return baseUrl;
    };
    // Función para codificar en Base64 considerando caracteres no ASCII
    const codificarBase64 = (texto) => {
      return window.btoa(unescape(encodeURIComponent(texto)));
    };
    // Ejecutar cuando se cargue la aplicación
    const baseUrl = getBaseUrl();
    const baseUrlCodificada = codificarBase64(baseUrl);

    console.log("URL Base:", baseUrl);
    console.log("URL Base codificada en Base64:", baseUrlCodificada);
    setUrl(baseUrlCodificada)
  }, []);

  return (
    url && (<form id="form-sence" hidden method="POST" action={SENCE_PROD.URL_LOGIN}>
      <input id="RutOtec" defaultValue={SENCE_PROD.RUT_OTEC} name="RutOtec" />
      <input id="Token" defaultValue={SENCE_PROD.TOKEN} name="Token" />
      <input id="CodSence" defaultValue={""} name="CodSence" />
      <input id="CodigoCurso" defaultValue={""} name="CodigoCurso" />
      <input
        id="LineaCapacitacion"
        defaultValue={SENCE_PROD.LINEA_CAPACITACION}
        name="LineaCapacitacion"
      />
      <input id="RunAlumno" defaultValue={""} name="RunAlumno" />
      <input id="IdSesionAlumno" defaultValue={""} name="IdSesionAlumno" />
      <input id="IdSesionSence" defaultValue={""} name="IdSesionSence" />
      <input
        id="UrlRetoma"
        /*   defaultValue={SENCE_DEV.URL_RETURN_LOGIN} */
        defaultValue={`${SENCE_PROD.URL_RETURN_LOGIN}?u=${url}`}
        name="UrlRetoma"
      />
      {/*  <input id="UrlError" defaultValue={SENCE_DEV.URL_LOGIN_ERROR} name="UrlError" /> */}
      {
        <input
          id="UrlError"
          defaultValue={`${SENCE_PROD.URL_LOGIN_ERROR}?u=${url}`}
          name="UrlError"
        />
      }
    </form>)
  );
};
