import React from "react";
import { useAuth } from "../../contexts/AuthContext";

export const ChangePasswordForm = ({
  handleChange,
  handleSubmit,
  loading,
  credentials,
}) => {
  const { lang } = useAuth();

  React.useEffect(() => {
    lang && console.log(lang["Recovery"]);
  }, [lang]);
  return (
    <form
      onSubmit={handleSubmit}
      action="https://par.tuclase.cl/login/index.php"
      method="post"
    >
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="password"
          className="form-control box-ingreso2"
          placeholder={lang["Recovery"].new_pass}
          value={credentials.password}
        />
      </div>
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="confirm_password"
          className="form-control box-ingreso2"
          placeholder={lang["Recovery"].new_pass_confirm}
          value={credentials.confirm_password}
        />
      </div>
      <div className="col-md-12">
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block bot-text"
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-md mx-3"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>{lang["Recovery"].new_pass_send}</>
          )}
        </button>
      </div>
    </form>
  );
};
