import { THEME_SEVERITY } from "./theme";

export const DEFAULT_SNACKBAR = {
  visible: false,
  message: "",
  severity: THEME_SEVERITY.DARK,
};

export const SENCE_WARNING = {
  visible: true,
  message: "Para navegar por el sitio debes cerrar tu sesión sence",
  severity: THEME_SEVERITY.DARK,
};

export const SUCCESS_CREDENTIALS = {
  visible: true,
  message: "Inicio de sesión exitoso",
  severity: THEME_SEVERITY.SUCCESS,
};

export const ERROR_CREDENTIALS = {
  visible: true,
  message: "Acceso inválido",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_EMPTY_CREDENTIALS = {
  visible: true,
  message: "Debe completar todos los campos",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_EMPTY_CREDENTIALS_USER = {
  visible: true,
  message: "Debe completar el campo usuario",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_EMPTY_CREDENTIALS_EMAIL = {
  visible: true,
  message: "Debe completar el campo correo",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_TICKET_FORM = {
  visible: true,
  message: "Debe completar todos los campos",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_TICKET_SERVER = {
  visible: true,
  message: "Error al enviar el formulario, intente mas tarde",
  severity: THEME_SEVERITY.ERROR,
};

export const SUCCESS_TICKET = {
  visible: true,
  message: "Mensaje enviado exitosamente",
  severity: THEME_SEVERITY.SUCCESS,
};

export const SUCCESS_EMAIL_SENT = {
  visible: true,
  message: "Solicitud enviada, revise su correo para continuar",
  severity: THEME_SEVERITY.SUCCESS,
};

export const SUCCESS_PASSWORD_CHANGE = {
  visible: true,
  message: "Contraseña cambiada con éxito",
  severity: THEME_SEVERITY.SUCCESS,
};

export const ERROR_DIFFERENT_PASSWORDS = {
  visible: true,
  message: "Contraseñas ingresadas no coinciden",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_FETCH_PASSWORD = {
  visible: true,
  message: "Error al conectar con el servidor",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_FETCH_PASSWORD_USER = {
  visible: true,
  message: "Usuario no existe",
  severity: THEME_SEVERITY.ERROR,
};

export const ERROR_FETCH_PASSWORD_EMAIL = {
  visible: true,
  message: "Correo no existe",
  severity: THEME_SEVERITY.ERROR,
};

export const SUCCESS_FORCEPASSWORD_CHANGE = {
  visible: true,
  message:
    "Contraseña creada con exito, se redirigirá a Iniciar Sesion para validar su cuenta",
  severity: THEME_SEVERITY.SUCCESS,
};
