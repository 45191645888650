import React from "react";
import { Link } from "react-router-dom";

export const LoginForm = ({
  handleChange,
  handleSubmit,
  loading,
  credentials,
  lang,
}) => {
  // React.useEffect(() => {}, [lang]);
  return (
    <form
      onSubmit={handleSubmit}
      action="https://par.tuclase.cl/login/index.php"
      method="post"
    >
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="text"
          name="username"
          className="form-control box-ingreso2"
          placeholder={lang.username}
          value={credentials.username}
        />
      </div>
      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="password"
          name="password"
          className="form-control box-ingreso2"
          placeholder={lang.password}
          value={credentials.password}
        />
      </div>
      <div className="col-md-12">
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block bot-text"
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-md mx-3"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>{lang.send}</>
          )}
        </button>
      </div>
      <div className="col-md-12">
        <p className="cuenta">
          <Link to="/change-password">{lang.recovery}</Link>
        </p>
      </div>
    </form>
  );
};
