import React from "react";
import { useAuth } from "../../contexts/AuthContext";

const Wrapper = ({ children }) => {
  const { lang } = useAuth();

  React.useEffect(() => {
    lang && console.log(lang["Recovery"]);
  }, [lang]);
  return (
    <>
      {lang && (
        <>
          <div className="container esp-log ">
            <img
              src="https://par.tuclase.cl/loginalt/images/logo2.png"
              alt="logo"
              className="posi-logo posi-log-b"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4  trans2 sac-new">
                <div className="fon-logo-new2 sec-desktop">
                  <p className="">
                    <img
                      src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                      alt="..."
                    />
                  </p>
                  <p className="iniciar-new">{lang["Recovery"].title}</p>
                </div>
                <div className="fon-logo-new2 sec-movil">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-xs-8 col-md-8">
                        <p className="">
                          <img
                            alt="img"
                            src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                            className="img-responsive"
                          />
                        </p>
                      </div>
                      <div className="col-xs-4 col-md-4"></div>
                    </div>
                    <p className="iniciar-new">
                      {lang["Recovery"].new_pass_send}
                    </p>
                  </div>
                </div>
                {children}
              </div>
              <div className="col-md-7 text-center salir">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4"></div>
                </div>
                <h1 className="llamado" style={{ paddingTop: "90px" }}>
                  {lang["Recovery"].instructions_4}
                </h1>
                <p className="llamado-d"></p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Wrapper;
