import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
/* import { removeHTML } from '../../utils' */
import flecha_s from "../HeaderCourses/assets/flecha-s.png";
import { useTheme } from "../../contexts/ThemeContext";
/* import { ProgressBar } from '../ProgressBar' */

export const CourseCard = ({ item = null, moodle, lang }) => {
  const theme = useTheme();
  /*   const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }; */
  const navigation = useNavigate();
  /*   const enddate = new Date(item.startdate).toDateString() */

  const backgroundref = React.useRef(null);

  React.useEffect(() => {
    if (item.overviewfiles.length > 0) {
      let imgurl = moodle.getCourseImg(item);
      backgroundref.current.style.backgroundImage = `url(${imgurl})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = (percentage) => {
    /* navigation(`/detailcourse/${percentage}/${item.id}`); */
    // localStorage.setItem("courseProgress", JSON.stringify(percentage));
    navigation(`/detailcourse/${item.id}`);
  };

  return (
    <div className="col-md-4 my-5 course-card" onClick={handleNavigation}>
      <div
        ref={backgroundref}
        className="fot-1 course-card-img"
        style={{
          height: "80%",
          borderRadius: 12,
          padding: 10,
          paddingTop: 200,
          width: "92%",
        }}
      >
        <div
          className="box-text course-card-info"
          style={theme.styles.background_primary}
        >
          <h2
            className="tit-box"
            style={{ ...theme.styles.title_text, margin: 0, padding: 0 }}
          >
            {item.fullname}
          </h2>
          <div>
            <div>
              <progress
                id="file"
                max="100"
                value={item?.progress?.toFixed(0) || 0}
              >
                {item?.progress?.toFixed(0) || 0}%
              </progress>
            </div>
            <div>
              <p
                className="tex-sec-car"
                style={{ padding: 0, ...theme.styles.title_text }}
              >
                {item?.progress?.toFixed(0) || 0}%{" "}
                {lang["Home"].course_progress}
              </p>
            </div>
          </div>
          <p className="text-box" style={{ margin: 0, padding: 0 }}>
            <button
              type="button"
              className="btn btn-primary bot-generico"
              style={{ margin: 0, ...theme.styles.darkmodebtn2 }}
              onClick={() => handleNavigation(item?.progress?.toFixed(0) || 0)}
            >
              <img src={flecha_s} alt="..." className="" />
              {lang["Home"].course_btn}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
