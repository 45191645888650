import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import log_menu from "./assets/log-menu.png";
/* import usuario from './assets/usuario.png' */
// import modo_dia from "./assets/modoo-dia.png";
// import modo_noche from "./assets/modo-noche.png";
import logo_color from "../../assets/logo_colors.png";
import menu from "./assets/list.svg";
import watch from "./assets/stopwatch-fill.svg";
import React from "react";
import { CustomDialog } from "../../components/Dialog";
import { useTheme } from "../../contexts/ThemeContext";
import { SenceValidation } from "../../services/sence";
import { useTimer } from "../Timer";
import { THEME_CONTEXT } from "../../utils/theme";
import { LIMIT_CRON_AVG } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import { DEFAULT_SNACKBAR, SENCE_WARNING } from "../../utils/snackbar";
import LogoutIcon from "@mui/icons-material/Logout";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { IconButton } from "@mui/material";
import { useMoodleV2 } from "../../contexts/moodle";

/* to work on */
export const Navbar = ({ layout, handleContentLayout }) => {
  const { time, limit } = useTimer();
  const auth = useAuth();
  const { lang } = useAuth();
  const theme = useTheme();
  const navref = React.useRef(null);
  const isSence = localStorage.getItem("sence_session");
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const [open, setOpen] = React.useState(false);
  // const icon = theme.mode === "light" ? modo_dia : modo_noche;
  const [sidebar, toggleSidebar] = React.useState(false);
  const navigate = useNavigate();
  const moodlev2 = useMoodleV2();

  React.useEffect(() => {
    if (limit > LIMIT_CRON_AVG) {
      handleSenceLogout();
      localStorage.removeItem("last_login_sence");
      localStorage.removeItem("sence_session");
      localStorage.removeItem("current_course");
      localStorage.removeItem("idSence");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  React.useEffect(() => {
    lang && console.log(lang["Navbar"]);
  }, [lang]);

  const handleLogout = () => {
    setOpen(true);
  };
  const handleLogoutCompleted = () => {
    setOpen(false);
    auth.logout(moodlev2);
    navigate("/");
  };

  const handleDarkMode = () => {
    theme.actions.handleDarkMode();
  };

  const handleSenceLogout = () => {
    let id = localStorage.getItem("idSence");
    if (!id) {
      return;
    }
    let sence = JSON.parse(localStorage.getItem("sence_session"));
    let nru = localStorage.getItem("nru");
    let validation = {
      idSence: id,
      auth,
      session: sence,
      isLogin: false,
      nru,
    };
    /*     console.log(sence) */
    SenceValidation(validation);
  };

  let disabled = {
    pointerEvents: isSence ? "none" : "",
    opacity: isSence ? 0.5 : 1,
  };

  let timer = null;

  const handleSenceWarning = () => {
    if (!isSence) {
      return;
    }
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
      return;
    } else {
      timer = setTimeout(closeSenceWarning, 2000);
      setSnackbar(SENCE_WARNING);
      return;
    }
  };

  const closeSenceWarning = () => {
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const handleSidebar = () => {
    let { style } = document.querySelector(".sidebar-container");
    if (!sidebar) {
      style.left = "0";
      return toggleSidebar(!sidebar);
    }

    style.left = "-350px";
    toggleSidebar(!sidebar);
  };

  return (
    <>
      {lang && (
        <nav ref={navref} style={theme.styles.nav}>
          <div
            className="nav_block"
            onMouseEnter={handleSenceWarning}
            onMouseLeave={handleSenceWarning}
          >
            <div className="logo">
              {theme.mode === "light" ? (
                <img alt="img" src={logo_color} height={55} width={105} />
              ) : (
                <img alt="img" src={log_menu} height={50} width={115} />
              )}
            </div>
            <div className="list">
              <ul className="navlist" datatoggle="tooltip">
                <Link to={"/listcourses"} style={disabled}>
                  <li
                    className="list-item"
                    style={{
                      color: theme.mode === "light" ? "#666666" : "#fff",
                    }}
                  >
                    {lang["Navbar"].home}
                  </li>
                </Link>
                <Link to={"/listcourses/progress"} style={disabled}>
                  <li
                    className="list-item"
                    style={{
                      color: theme.mode === "light" ? "#666666" : "#fff",
                    }}
                  >
                    {lang["Navbar"].history}
                  </li>
                </Link>
                {/*               <li className='list-item'>Malla</li>
              <li className='list-item'>DNC</li> */}
                <Link to={"/contact"} style={disabled}>
                  <li
                    className="list-item"
                    style={{
                      color: theme.mode === "light" ? "#666666" : "#fff",
                    }}
                  >
                    {lang["Navbar"].contact}
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="nav_block">
            <div className="row menu">
              <div className="col-3 col-md-3">
                <img
                  src={menu}
                  alt="..."
                  className="burger"
                  onClick={handleSidebar}
                />
              </div>
            </div>
            {isSence && (
              <div
                className="student_profile stopwatch"
                style={{
                  cursor: "pointer",
                  justifyContent: "space-between",
                  backgroundColor: THEME_CONTEXT.DAY_MODE_BTN,
                  borderRadius: "10px",
                  margin: "10px",
                  padding: "7px 10px",
                  ...theme.styles.darkmodebtn,
                }}
                onClick={handleSenceLogout}
              >
                <img src={watch} alt="..." />
                <p>{time}</p>
                <p>Cerrar Sence</p>
              </div>
            )}

            {window.location.pathname.includes("detailcourse") && (
              <div
                className="student_profile menu-handler-button"
                style={{
                  margin: 0,
                  transform: layout ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <IconButton
                  onClick={handleContentLayout}
                  sx={{ padding: "5px" }}
                >
                  {layout ? (
                    // <KeyboardArrowRightIcon
                    //   sx={{
                    //     color: theme.mode === "light" ? "#fff" : "#fff",
                    //     fontSize: 30,
                    //   }}
                    // />
                    <MenuOpenIcon
                      sx={{
                        color: theme.mode === "light" ? "#fff" : "#fff",
                        fontSize: 35,
                      }}
                    />
                  ) : (
                    // <KeyboardArrowLeftIcon
                    //   sx={{
                    //     color: theme.mode === "light" ? "#fff" : "#fff",
                    //     fontSize: 30,
                    //   }}
                    // />
                    <MenuOpenIcon
                      sx={{
                        color: theme.mode === "light" ? "#fff" : "#fff",
                        fontSize: 35,
                      }}
                    />
                  )}
                </IconButton>
              </div>
            )}

            <div className="student_profile">
              <p style={{ color: theme.mode === "light" ? "#666666" : "#fff" }}>
                {auth && auth.user?.fullname}
              </p>
              <img
                alt="img"
                src={auth.user.profileimageurlsmall}
                style={{ borderRadius: "50%" }}
              />
            </div>

            <div
              className="modo_dia"
              onClick={handleDarkMode}
              style={theme.styles.darkmodebtn}
            >
              {theme.mode === "light" ? (
                <LightModeIcon sx={{ fontSize: 22 }} />
              ) : (
                <DarkModeIcon sx={{ fontSize: 22 }} />
              )}
            </div>
            <div className="logout" onClick={handleLogout}>
              <LogoutIcon
                sx={{
                  fontSize: 30,
                  color: theme.mode === "light" ? "#666666" : "#fff",
                }}
              />
            </div>
          </div>
          <Snackbar
            message={snackbar.message}
            severity={snackbar.severity}
            visible={snackbar.visible}
          />
          <CustomDialog
            open={open}
            message={lang["Snackbars"].acc_logout_message}
            confirm={lang["Snackbars"].confirm}
            cancel={lang["Snackbars"].cancel}
            sence={isSence}
            onCancel={() => setOpen(false)}
            onConfirm={handleLogoutCompleted}
          />
        </nav>
      )}
    </>
  );
};
